<template>
  <v-app-bar app color="white" style="max-height:56px;" dense>
    <v-app-bar-nav-icon
      @click.stop="drawer = !drawer"
      v-show="!$vuetify.breakpoint.smAndUp"
    ></v-app-bar-nav-icon>

    <a href="/" style="padding:0;padding-right:5px;text-decoration:none;">
      <span class="logo-lg" style="color:#7192B0;"
        ><b style="color:#053768">Trust</b>Monitor</span
      > </a
    ><span v-if="private_instance" style="color:pink;font-weight:bold;"
      >PRIVATE INSTANCE</span
    >

    <v-spacer></v-spacer>
    <!--
    <v-form
      v-if="$vuetify.breakpoint.smAndUp"
      style="text-decoration:none;min-width:130px;"
      class="ma-0 pa-0"
    >
      <v-text-field
        v-model="search"
        hide-no-data
        solo
        dense
        hide-details
        prepend-icon="mdi-database-search"
        class="custom-placeholder ma-0 pa-0 ml-n6"
        placeholder="DN, serial, algo, etc"
        aria-label="Search"
      ></v-text-field>

      <v-card v-if="dialog" style="z-index:99001 !important;" class="pa-2">
        <span style="font-size:.85em;">{{ count }} Search Results</span>

        <CertTile
          v-for="certificate in items"
          v-bind:key="certificate.id"
          :certificate="certificate"
        ></CertTile>

        <v-btn block color="primary" v-if="count > 2">View All</v-btn>
      </v-card>
    </v-form>
    <v-btn v-else icon>
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
    -->

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
          color="primary"
        >
          <v-badge color="secondary" overlap bordered>
            <template v-slot:badge>
              <span>0</span>
            </template>

            <v-icon aria-label="Notifications">mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-title v-text="n" />
          </app-bar-item>
          <app-bar-item v-if="notifications.length === 0">
            <v-list-item-title>No notifications</v-list-item-title>
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-menu offset-y v-if="auth">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          min-width="0"
          v-bind="attrs"
          v-on="on"
          text
          class="ml-2"
        >
          <v-icon aria-label="Menu">mdi-account-cog</v-icon>
        </v-btn>
      </template>
      <v-list>
        <app-bar-item link to="/groups/">
          <v-list-item-icon class="mr-1">
            <v-icon color="primary">mdi-settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Monitoring Groups</v-list-item-title>
        </app-bar-item>
        <app-bar-item>
          <v-list-item-icon class="mr-1">
            <v-icon color="primary">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title @click.stop="onLogout()"
            >Sign out</v-list-item-title
          >
        </app-bar-item>
      </v-list>
    </v-menu>
    <template v-else> </template>

    <v-progress-linear
      :active="page_loading"
      :indeterminate="page_loading"
      absolute
      bottom
      color="primary accent-4"
      top
    ></v-progress-linear>
  </v-app-bar>
</template>

<style scoped></style>
<script>
import axios from "axios";
import { VHover, VListItem } from "vuetify/lib";

export default {
  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs
                  }
                },
                this.$slots.default
              );
            }
          }
        });
      }
    }
  },
  data: function() {
    return {
      dialog: false,
      search: null,
      count: null,
      items: [],
      notifications: []
    };
  },
  computed: {
    page_loading() {
      return this.$store.getters.page_loading;
    },
    auth() {
      return this.$store.getters.ifAuthenticated;
    },
    private_instance() {
      return this.$store.getters.instance.private;
    },
    token_json() {
      return this.$store.getters.token_json;
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(newValue) {
        this.$store.state.drawer = newValue;
      }
    }
  },
  methods: {
    onLogout() {
      this.$store.dispatch("logout");
    }
  },
  watch: {
    search(val) {
      this.dialog = true;
      axios
        .get(this.$store.state.api_domain + "/certificates/?search=" + val)
        .then(response => {
          this.count = response.data.count;
          this.items = response.data.results.slice(0, 2);
        });
    }
  }
};
</script>
